import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBooking } from "../contexts/booking";
import { useTranslation } from "react-i18next";

function ConfirmPage() {
    const { lang, token } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { date, time, seats, business } = useBooking();

    React.useEffect(() => {
        let t = setTimeout(() => navigate(`/${lang}/${token}`), 60 * 1000);

        return () => clearTimeout(t);
    }, []);

    return (
        <div className="max-w-xl md:mx-auto m-4 p-8 space-y-4 rounded-md shadow-md">
            <p className="text-2xl text-center">{t("confirmPage.p_1")}</p>

            <p className="text-2xl text-center">{business.attributes.name}</p>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <rect fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" x="48" y="80" width="416" height="384" rx="48" />
                    <circle cx="296" cy="232" r="24" />
                    <circle cx="376" cy="232" r="24" />
                    <circle cx="296" cy="312" r="24" />
                    <circle cx="376" cy="312" r="24" />
                    <circle cx="136" cy="312" r="24" />
                    <circle cx="216" cy="312" r="24" />
                    <circle cx="136" cy="392" r="24" />
                    <circle cx="216" cy="392" r="24" />
                    <circle cx="296" cy="392" r="24" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" strokeLinecap="round" d="M128 48v32M384 48v32" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M464 160H48" />
                </svg>

                <span className="font-bold">{date.toLocaleString(lang, { 'weekday': 'long', 'month': 'long', 'day': '2-digit' })}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96" />
                </svg>

                <span className="font-bold">{time}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                </svg>

                <span className="font-bold">{seats} {seats > 1 ? t("confirmPage.span_1_b") : t("confirmPage.span_1_a")}</span>
            </div>

            <p className="text-xl">{t("confirmPage.p_2")}</p>
            <p>{t("confirmPage.p_3")}</p>
            <p>{t("confirmPage.p_4")}</p>
        </div>
    );
}

export default ConfirmPage;
