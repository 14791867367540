function formatUrl(url, obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const token = `:${key}`;
            url = String(url).replace(token, encodeURIComponent(obj[key]));
        }
    }
    return url;
}

function generateSeries(date, step) {
    const serie = [];
    const start = new Date(date);
    const limit = new Date(date);

    while (start.getDate() === limit.getDate()) {
        if (start.getMinutes() >= 45) {
            start.setMinutes(start.getMinutes() + step);
            serie.push(String(start.getHours()).padStart(2, "0"));
        } else {
            serie.push(String(start.getHours()).padStart(2, "0"));
            start.setMinutes(start.getMinutes() + step);
        }
    }

    return serie;
}

function generateTimeSeries(date) {
    const start = roundToNearest15(date);
    const limit = new Date(date);

    const series = [];

    while (start.getDate() === limit.getDate()) {
        start.setMinutes(start.getMinutes() + 15);
        series.push(start.toLocaleTimeString("it-IT", { hour: "2-digit", minute: "2-digit" }));
    }

    return series;
}

function roundToNearest15(date = new Date()) {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;

    // replace Math.round with Math.ceil to always round UP
    return new Date(Math.round(date.getTime() / ms) * ms);
}
/*
function getNumberOfPeople(people) {
    let p = parseInt(people);
    const aux = [];

    const persona = "Persona";
    const persone = "Persone";

    for (let i = 1; i <= p; i++) {
        i == 1 ?
            aux.push({ value: i, text: persona }) :
            aux.push({ value: i, text: persone })
    }

    return aux;
}
*/
// function generate_series(step) {
//     const dt = new Date();
//     const dt1 = new Date();

//     const rc = [];
//     const steps = [];

//     while (dt.getDate() === dt1.getDate()) {
//       rc.push(dt.toLocaleTimeString());
//       dt.setMinutes(dt.getMinutes() + step);
//     }

//     for(step in steps){

//     }

//     return rc;
//   }

//   function on_generate_series(step) {
//     const series = generate_series(step);
//     while (series.length > 0) {
//       let item = series.shift();
//       console.log(item);
//     }
//   }
//   on_generate_series(15);


//   function roundToNearest15(date = new Date()) {
//     const minutes = 15;
//     const ms = 1000 * 60 * minutes;

//     // 👇️ replace Math.round with Math.ceil to always round UP
//     return new Date(Math.round(date.getTime() / ms) * ms);
//   }

//   // 👇️ now
//   //console.log(roundToNearest15(new Date()));

//   // 👇️ Mon Jan 24 2022 09:15:00 (minutes are 8)
//   //console.log(roundToNearest15(new Date(2022, 0, 24, 9, 8)));


export default {
    formatUrl,
    generateSeries,
    generateTimeSeries,
    roundToNearest15,
    //getNumberOfPeople
}
