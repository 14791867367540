import axios from "axios";

//const DOMAIN_URL = "https://api2022stag.sdch.develondigital.com";
const DOMAIN_URL = "https://orders.mycontactlessmenu.mycia.it";
//const DOMAIN_URL = "http://localhost:8000";
const VERSION = "v1";

function url(path) {
    return `${DOMAIN_URL}/${path}`;
}

async function getRaw(url, config) {
    console.log("[GET]", url);
    console.log("[-CONFIG]", config);

    try {
        return await axios.get(url, config);
    } catch (err) {
        //console.log("[Catch]", err.toJSON());
        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(err.response.data);
            console.log("[Response]", err.response.status);
            console.log("[Response]", err.response);
            // console.log(err.response.headers);
            return err.response;
        } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("[Request]", err.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("[Error]", err.message);
        }
        //console.log(err.config);
    }
}

async function postRaw(url, data, config) {
    console.log("[POST]", url);
    console.log("[-CONFIG]", config);
    console.log("[-DATA]", JSON.stringify(data));

    try {
        return await axios.post(url, data, config);
    } catch (err) {
        //console.log("[Catch]", err.toJSON());
        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(err.response.data);
            console.log("[Response]", err.response.status);
            console.log("[Response]", err.response);
            // console.log(err.response.headers);
            return err.response;
        } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("[Request]", err.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("[Error]", err.message);
        }
        //console.log(err.config);
    }
}

async function getRelated(model, relation, config) {
    return await getRaw(model.relationships[relation].links.related, config);
}

async function getAvailabilitySlots(token, date, time, seats) {
    return await getRaw(url(`${VERSION}/bookings/availability?token=${token}&date=${date}&time=${time}&seats=${seats}`), {});
}

async function getAvailabilitySlotsOld(token, date, time, seats) {
    return await getRaw(url(`api/bookings/availability?token=${token}&date=${date}&time=${time}&seats=${seats}`), {});
}

async function filter(name, token) {
    return await getRaw(url(`${VERSION}/${name}?filter[token]=${token}`), {});
}

async function getBusiness(token) {
    return await filter("businesses", token);
}

async function getBusinessOld(token) {
    return await getRaw(url(`api/businesses/${token}`));
}

async function book(userdata) {
    let [hours, minutes] = userdata.time.split(":");
    userdata.date.setHours(hours);
    userdata.date.setMinutes(minutes);

    let data = {
        data: {
            livemode: false,
            name: `${userdata.fname} ${userdata.lname}`,
            email: userdata.email,
            phone: `+39${userdata.phone}`,
            seats: Number(userdata.seats),
            token: userdata.token,
            reserved_at: userdata.date,
        }
    }
    //console.log(data);
    return await postRaw(url(`${VERSION}/bookings/book`), data);
}

async function bookOld(userdata) {
    let [hours, minutes] = userdata.time.split(":");
    userdata.date.setHours(hours);
    userdata.date.setMinutes(minutes);

    let data = {
        customer: `${userdata.fname} ${userdata.lname}`,
        email: userdata.email,
        phone: `+39${userdata.phone}`,
        seats: Number(userdata.seats),
        token: userdata.token,
        reserved_at: userdata.date.toISOString().split('T')[0],
        reserved_time: userdata.time,
    }
    //console.log(data);
    return await postRaw(url(`api/bookings`), data);
}

export default {
    getRelated,
    getAvailabilitySlots,
    getAvailabilitySlotsOld,
    filter,
    getBusiness,
    getBusinessOld,
    book,
    bookOld,
}
