import React from "react";
import client from "../apis";

const BookingContext = React.createContext();

function BookingProvider(props) {
    const [token, setToken] = React.useState();
    const [business, setBusiness] = React.useState();
    const [date, setDate] = React.useState();
    const [time, setTime] = React.useState();
    const [seats, setSeats] = React.useState();

    async function loadBusiness(token) {
        let response = await client.filter("businesses", token);
        console.log(response.data.data[0])
        setBusiness(response.data.data[0]);
    }

    async function loadBusinessOld(token) {
        let response = await client.getBusinessOld(token);
        console.log(response.data)
        setBusiness({ attributes: { ...response.data } });
    }

    React.useEffect(() => {
        token && loadBusinessOld(token);
    }, [token]);

    const value = {
        token,
        business,
        date,
        time,
        seats,
        setToken,
        loadBusiness,
        setDate,
        setTime,
        setSeats,
    }

    return <BookingContext.Provider value={value} {...props} />;
}

function useBooking() {
    const context = React.useContext(BookingContext);
    if (!context) {
        throw new Error("useBooking must be used within a BookingProvider");
    }
    return context;
}

export {
    BookingProvider,
    useBooking,
}
