import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import utils from "../utils";
import { useBooking } from "../contexts/booking";
import { useTranslation } from "react-i18next";
import "../styles/calendar.css";

function CalendarPage() {
    const { lang, token } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { setDate, setTime, setSeats, setToken } = useBooking();

    const [userdate, setUserdate] = React.useState(new Date());

    React.useEffect(() => {
        i18n.changeLanguage(lang);
        setDate(null);
        setTime(null);
        setSeats(null);
    }, []);

    React.useEffect(() => {
        setToken(token);
    }, [token]);

    const onChangeHandler = d => {
        d.setHours(12);
        setUserdate(d);
    }

    const onSubmitHandler = evt => {
        evt.preventDefault();

        const formdata = new FormData(evt.target);
        const userdata = Object.fromEntries(formdata);

        setDate(userdate);
        setTime(userdata.time);
        setSeats(userdata.seats);

        navigate(`/${lang}/${token}/availability`);
    }

    return (
        <div className="max-w-xl md:mx-auto m-4 p-8 space-y-8 rounded-md shadow-md">
            <p className="text-2xl text-center">{t("calendarPage.p_1")}</p>

            <Calendar className="md:mx-10" locale={lang} minDate={new Date()} prev2Label={null} next2Label={null} onChange={onChangeHandler} value={userdate} />

            <form method="get" onSubmit={onSubmitHandler}>
                <fieldset className="my-4">
                    <legend className="sr-only">{t("calendarPage.legend_1")}</legend>

                    <div className="mb-4">
                        <label htmlFor="time" className="sr-only">{t("calendarPage.label_1")}</label>

                        <div className="flex gap-4 px-4 py-2 border rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                                <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96" />
                            </svg>

                            <select name="time" id="time" className="w-full text-center bg-white">
                                {utils.generateTimeSeries(new Date(userdate)).map((time, index) => <option value={time} key={index}>{time}</option>)}
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="seats" className="sr-only">{t("calendarPage.label_2")}</label>

                        <div className="flex gap-4 px-4 py-2 border rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                            </svg>

                            <select name="seats" id="seats" className="w-full text-center bg-white">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id, index) =>
                                    <option value={id} key={index}>
                                        {id} {id === 1 ?
                                            t("calendarPage.select_1.option_1") :
                                            t("calendarPage.select_1.option_2")}
                                    </option>)}
                            </select>
                        </div>
                    </div>
                </fieldset>

                <button type="submit" className="w-full px-4 py-2 text-center text-white uppercase rounded-md bg-mycia-red">{t("calendarPage.button_1")}</button>
            </form>
        </div>
    );
}

export default CalendarPage;
