import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BookingProvider } from "./contexts/booking";
import CalendarPage from "./pages/CalendarPage";
import AvailabilityPage from "./pages/AvailabilityPage";
import ContactPage from "./pages/ContactPage";
import ConfirmPage from "./pages/ConfirmPage";
import HomePage from "./pages/HomePage";

function App() {
    return (
        <div className="App">
            <BookingProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/:lang" element={<HomePage />} />
                        <Route path="/:lang/:token" element={<CalendarPage />} />
                        <Route path="/:lang/:token/availability" element={<AvailabilityPage />} />
                        <Route path="/:lang/:token/contact" element={<ContactPage />} />
                        <Route path="/:lang/:token/confirm" element={<ConfirmPage />} />
                    </Routes>
                </BrowserRouter>
            </BookingProvider>
        </div>
    );
}

export default App;
